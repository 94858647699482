import { DocumentProperty, Dimension, TimestampedDocument, DocumentProperties } from "./document";

export enum RequiredDocumentJobType {
    MAIN = "main",
    ADDITIONAL_1 = "additional1",
    ADDITIONAL_2 = "additional2",
}

export type RequiredDocumentAmount = {
    amount: number;
    label: string;
};

export enum RequiredDocumentSpecialId {
    REFINANCING_LIABILITY_ID = "refinancingLiabilityId",
}

export type RequiredDocument = {
    // The id is the same as the ID of the corresponding dimension
    id: string;
    value?: Dimension;
    parentSubCategory?: Dimension;
    lastNMonths?: number;
    lastNYears?: number;
    allMonthsIncluded?: boolean;
    requiredDocumentAmount?: RequiredDocumentAmount;
    documentProperty?: DocumentProperty;
    requiredDocumentJobType?: RequiredDocumentJobType;
    liabilityId?: string;
};

export type ReducedRequiredDocument = Omit<RequiredDocument, "documentProperty" | "allMonthsIncluded" | "value">;

export type ManualRequiredDocuments = {
    added: RequiredDocument[];
    removed: RequiredDocument[];
    modified: RequiredDocument[];
};

export type ReducedManualRequiredDocuments = {
    added: ReducedRequiredDocument[];
    removed: ReducedRequiredDocument[];
    modified: ReducedRequiredDocument[];
};

export type DefaultRequiredDocument = Omit<RequiredDocument, "id">;

export type RequiredDocumentProperties = {
    id?: string;
    parentSubCategory?: string;
    lastNMonths?: number;
    lastNYears?: number;
    requiredDocumentAmount?: { label: string; amount: number };
    requiredDocumentJobType?: RequiredDocumentJobType;
    liabilityId?: string;
} & Pick<DocumentProperties, "typeId" | "objectId" | "personId">;

export type RequiredDocuments<T extends TimestampedDocument = TimestampedDocument> = Partial<
    Record<
        string,
        Record<
            DocumentProperty["id"],
            {
                lastNMonths?: RequiredDocumentProperties["lastNMonths"];
                lastNYears?: RequiredDocumentProperties["lastNYears"];
                requiredDocumentAmount?: RequiredDocumentProperties["requiredDocumentAmount"];
                requiredDocumentJobType?: RequiredDocumentProperties["requiredDocumentJobType"];
                liabilityId?: RequiredDocumentProperties["liabilityId"];
                files: T[];
            }
        >
    > & {
        undefined?: { files: T[] };
    }
>;
